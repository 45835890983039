import React from 'react';
import Infobar from "./Infobar";
import InfobarMob from "./Infobar_mob";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Convenience from './img/Convenience.jpeg'
import Packing from './img/Deliver.jpeg'
import Damage from './img/Damage.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faMapMarkerAlt, faGlobe, faBuilding, faTruckMoving, faUsers, faCube, faMapMarkedAlt, faLightbulb, faWarehouse, faSearchLocation, faClipboardCheck, faHome } from '@fortawesome/free-solid-svg-icons';
import movers_packers from './img/movers_packers.jpg'


const AboutUs = () => {
    const services = [
        { icon: faShieldAlt, text: "37+ Years of Trust" },
        { icon: faMapMarkerAlt, text: "1264+ Service Locations" },
        { icon: faGlobe, text: "182 Worldwide Coverage" },
        { icon: faBuilding, text: "125+ Branches PAN India" },
        { icon: faTruckMoving, text: "132060+ Moves Annually" },
        { icon: faUsers, text: "Trained Manpower" },
        { icon: faCube, text: "3000+ Trucking Cube (Container)" },
        { icon: faMapMarkedAlt, text: "1200+ GPS Enabled Vehicles" },
        { icon: faLightbulb, text: "Innovative Technology" },
        { icon: faWarehouse, text: "20 Lac sq. ft warehouse space" },
        { icon: faSearchLocation, text: "Online Consignment Tracking" },
        { icon: faClipboardCheck, text: "Free Pre-Move Survey" },
        { icon: faHome, text: "7600+ Home Storage Lockers" },
    ];

    const features = [
        { image: Convenience, label: 'Convenience' },
        { image: Damage, label: 'Zero Damage' },
        { image: movers_packers, label: 'Safety and Security' },
        { image: Packing, label: 'Customized Packing' },
    ];

    return (
        <>
            <Infobar />
            <InfobarMob />
            <Navbar />
            <div className="bg-white text-gray-800 py-12 px-4 md:px-8">
                <div className="max-w-6xl mx-auto">

                    {/* Section 1: Introduction */}
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-black-600 mb-6">About Sure Shift</h1>
                        <p className="text-lg leading-relaxed">
                            Sure Shift is a leading movers and packers company based in Gurgaon, specializing in providing comprehensive relocation services across India and globally. With a steadfast commitment to excellence, we ensure a seamless and stress-free moving experience, whether you are relocating locally, nationally, or internationally.
                        </p>
                    </div>

                    {/* Image Placeholder */}
                    <div className="flex justify-center mb-12">
                        <img
                            src={movers_packers}
                            alt="Sure Shift Services"
                            className="w-full max-w-md rounded shadow-lg"
                        />
                    </div>

                    {/* Section 2: Our Services */}
                    <div className="mb-12">
                        <h2 className="text-3xl font-semibold text-black-600 mb-4">Our Services</h2>
                        <ul className="list-disc list-inside space-y-4 text-lg leading-relaxed">
                            <li>
                                <strong>Household Moving:</strong> We handle everything from small household items to large furniture, ensuring safe and secure transportation.
                            </li>
                            <li>
                                <strong>Car Moving:</strong> We offer specialized car transportation services to ensure your vehicle arrives safely at its new destination.
                            </li>
                            <li>
                                <strong>Secure Storage:</strong> Our secure storage facilities provide a safe place to store your belongings during your move.
                            </li>
                            <li>
                                <strong>International Moving:</strong> Our global network ensures that your belongings are transported safely and efficiently, no matter where you are moving in the world.
                            </li>
                            <li>
                                <strong>Office Shifting:</strong> We provide tailored solutions for corporate relocations, minimizing downtime and ensuring business continuity.
                            </li>
                            <li>
                                <strong>Commercial Moving:</strong> We manage the logistics of commercial relocations, handling everything from small offices to large enterprises.
                            </li>
                            <li>
                                <strong>Bike Moving:</strong> Our services include secure and reliable bike transportation, ensuring your two-wheeler reaches its destination without any damage.
                            </li>
                            <li>
                                <strong>Fine Arts & Sculptures Moving:</strong> We offer specialized packing and transportation services for delicate and valuable items like fine arts and sculptures.
                            </li>
                            <li>
                                <strong>Truck Rental:</strong> We provide truck rental services tailored to your specific moving needs, whether it's a small or large load.
                            </li>
                            <li>
                                <strong>Last Mile Delivery:</strong> Our last mile delivery services ensure your goods are delivered to their final destination with utmost care.
                            </li>
                            <li>
                                <strong>Courier:</strong> We offer reliable and fast courier services to ensure your packages are delivered on time.
                            </li>
                            <li>
                                <strong>ODC Consignment:</strong> We specialize in handling Over-Dimensional Cargo (ODC) consignments, ensuring safe and efficient transportation of oversized goods.
                            </li>
                        </ul>
                    </div>

                    {/* Image Placeholder
                    <div className="flex justify-center mb-12">
                        <img
                            src="/path-to-your-image.jpg"
                            alt="Our Services"
                            className="w-full max-w-md rounded shadow-lg"
                        />
                    </div> */}

                    {/* Section 3: Why Choose Us */}
                    <div className="text-center">
                        <h2 className="lg:text-4xl text-3xl font-bold text-black-600 mb-4">Why Choose Sure Shift?</h2>
                        <p className="text-lg leading-relaxed mb-6">
                            Our reputation is built on reliability, professionalism, and a customer-centric approach. We use the latest technology and best practices to ensure the safety and security of your belongings. With Sure Shift, you can rest assured that your move will be handled by experienced professionals who care about your satisfaction.
                        </p>
                    </div>
                </div>

                <div className="w-[100%] flex justify-center mt-[80px] lg:mt-[40px]">
                    <div className="lg:w-[80%] px-4 sm:px-6 flex flex-col lg:flex-col justify-center items-center">
                        {/* <div className="lg:text-center text-center flex flex-col lg:flex-col justify-center lg:w-[55%] ">
                            <h2 className="lg:text-4xl text-3xl font-bold mb-3">Why Choose Sureshift</h2>
                        </div> */}
                        <div className="flex flex-wrap flex-row justify-center mx-auto gap-2 p-4">
                            {services.map((service, index) => (
                                <div key={index} className="bg-white rounded-lg shadow-md px-2 py-4 lg:p-6 flex flex-wrap flex-col items-center lg:w-[150px] w-[90px] ">
                                    {/* <div key={index} className="bg-white rounded-lg shadow-md p-6 flex flex-wrap flex-col items-center lg:w-[150px] w-[130px] "> */}
                                    <div className="text-red-500 mb-4">
                                        <FontAwesomeIcon icon={service.icon} className='text-[18px] lg:text-[32px]' />
                                    </div>
                                    <div className="text-center text-xs lg:text-sm font-semibold text-gray-700">
                                        {service.text}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="w-[100%] flex flex-col justify-center items-center mt-[80px] lg:mt-[50px] ">
                    <div className="lg:w-[80%] px-4 sm:px-6 flex flex-col lg:flex-col justify-center items-center">
                        {/* Company Information */}
                        <div className="text-center mb-8 lg:mb-[50px]">
                            <h1 className="lg:text-4xl text-3xl font-bold">SURESHIFT PACKERS AND MOVERS LTD.</h1>
                            <p className="text-sm font-semibold text-gray-600">An ISO 9001:2015 & ISO 39001:2012 Certified Company</p>
                            <p className="text-lg font-bold text-red-600 mt-2">India’s Largest and Most Awarded Movers</p>
                            <p className="lg:text-lg text-base mt-4 max-w-2xl">
                                Surshift Packers and Movers Ltd is a globally recognized logistics company operating since 1987 and is recognized for imparting excellent services in packing and moving segments. We have designed our services proficiently to meet the maximum customer satisfaction and rendered them in such a way that comes out high on the expectations of our clients.
                            </p>
                            <a href='/#' className="text-red-500 font-semibold cursor-pointer mt-4 mb-2">Read More</a>
                        </div>

                        {/* Features Section */}
                        <div className="flex flex-wrap justify-center gap-4 w-[100%]">
                            {features.map((feature, index) => (
                                <div key={index} className="relative lg:flex-1 lg:w-[50%] w-[45%]">
                                    <img src={feature.image} alt={feature.label} className="w-full h-auto object-cover rounded-md shadow-md" />
                                    <div className="absolute lg:text-basic text-xs bottom-0 bg-black bg-opacity-50 text-white text-center w-full py-2 rounded-b-md">
                                        {feature.label}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
            </div>



            <Footer />
        </>
    );
};

export default AboutUs;
