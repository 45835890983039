import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { Autocomplete, LoadScript } from '@react-google-maps/api';

const options = [
    { id: 'household', label: 'Household Moving', icon: '🚚' },
    { id: 'car', label: 'Car Moving', icon: '🚗' },
    { id: 'secure', label: 'Secure Storage', icon: '🏠' },
    { id: 'international', label: 'International Moving', icon: '🌍' },
    { id: 'office', label: 'Office Shifting', icon: '🏢' },
    { id: 'commercial', label: 'Commercial Moving', icon: '🏭' },
    { id: 'bike', label: 'Bike Moving', icon: '🏍️' },
    { id: 'fine_arts', label: 'Fine Arts & Sculptures Moving', icon: '🎨' },
    { id: 'truck', label: 'Truck Rental', icon: '🚛' },
    { id: 'last_mile', label: 'Last Mile Delivery', icon: '🚚' },
    { id: 'courier', label: 'Courier', icon: '📦' },
    { id: 'odc_consignment', label: 'ODC Consignment', icon: '🚜' },
];

const UserForm2 = () => {
    const [selectedOption, setSelectedOption] = useState('car');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        pickup_date: '',
        pickup_time: '',
        pickup_address: '',
        drop_address: '',
        purpose: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [captchaToken, setCaptchaToken] = useState(null);
    const recaptchaRef = useRef(null); // Create a reference to the reCAPTCHA component
    const autocompletePickupRef = useRef(null); // Ref for Pickup Address Autocomplete
    const autocompleteDropRef = useRef(null); // Ref for Drop Address Autocomplete

    const handleOptionClick = (id) => {
        setSelectedOption(id);
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!captchaToken) {
            alert("Please complete the CAPTCHA");
            setIsLoading(false);
            return;
        }

        const updatedFormData = {
            ...formData,
            purpose: options.find(option => option.id === selectedOption)?.label || '',
            'g-recaptcha-response': captchaToken
        };

        try {
            await axios.post('/user', updatedFormData);
            alert('User data submitted successfully');
            handleReset();
        } catch (error) {
            console.error('Error submitting user data:', error);
            alert('Failed to submit user data');
        } finally {
            setIsLoading(false);
            setCaptchaToken(null);
        }
    };

    const handleReset = () => {
        setFormData({
            name: '',
            email: '',
            phone: '',
            pickup_date: '',
            pickup_time: '',
            pickup_address: '',
            drop_address: '',
            purpose: ''
        });
        setCaptchaToken(null);
        recaptchaRef.current.reset(); // Reset the reCAPTCHA widget
    };

    const onCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const handleAddressChange = (addressType, place) => {
        setFormData({
            ...formData,
            [addressType]: place.formatted_address
        });
    };

    const renderForm = () => {
        const commonFields = (
            <>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/3 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                            placeholder='Enter Name'
                        />
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Phone</label>
                        <input
                            type="text"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                            placeholder='Enter Phone No.'
                        />
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Email</label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                            placeholder='Enter Email'
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/3 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Pickup Address</label>
                        <Autocomplete
                            onLoad={(autocomplete) => (autocompletePickupRef.current = autocomplete)}
                            onPlaceChanged={() => handleAddressChange('pickup_address', autocompletePickupRef.current.getPlace())}
                        >
                            <input
                                type="text"
                                name="pickup_address"
                                value={formData.pickup_address}
                                onChange={handleChange}
                                className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                required
                            />
                        </Autocomplete>
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Drop Address</label>
                        <Autocomplete
                            onLoad={(autocomplete) => (autocompleteDropRef.current = autocomplete)}
                            onPlaceChanged={() => handleAddressChange('drop_address', autocompleteDropRef.current.getPlace())}
                        >
                            <input
                                type="text"
                                name="drop_address"
                                value={formData.drop_address}
                                onChange={handleChange}
                                className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                                required
                            />
                        </Autocomplete>
                    </div>
                    <div className="w-full md:w-1/3 lg:pl-2 mb-4">
                        <label className="block text-gray-700">Pickup Date</label>
                        <input
                            type="date"
                            name="pickup_date"
                            value={formData.pickup_date}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                </div>
                <div className="flex flex-wrap mb-4">
                    <div className="w-full md:w-1/3 lg:pr-2 mb-4">
                        <label className="block text-gray-700">Pickup Time</label>
                        <input
                            type="time"
                            name="pickup_time"
                            value={formData.pickup_time}
                            onChange={handleChange}
                            className="mt-2 p-2 block w-full border rounded-md placeholder-black text-black"
                            required
                        />
                    </div>
                </div>
                {/* reCAPTCHA */}
                <div className="flex justify-center mb-4">
                    <ReCAPTCHA
                        sitekey="6LcNBTUqAAAAANEVacfo2ApjLvEImEf8OeSlygJE" // Replace with your actual site key
                        onChange={onCaptchaChange}
                        ref={recaptchaRef} // Attach the ref
                    />
                </div>
                <div className="flex flex-wrap mb-4 justify-center lg:-mb-[30px]">
                    <div className="w-full md:w-1/3 lg:pr-2 mb-4">
                        <button
                            type="submit"
                            className={`w-full p-2 rounded-md lg:mb-5 ${isLoading ? 'bg-gray-500' : 'bg-[#0E2334]'} text-white`}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                    <div className="w-full md:w-1/3 lg:pr-2 lg:mb-4">
                        <button
                            type="button"
                            onClick={handleReset}
                            className="w-full bg-red-500 text-white p-2 rounded-md"
                            disabled={isLoading}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </>
        );

        return (
            <form onSubmit={handleSubmit}>
                <h2 className="text-2xl font-bold mb-6 text-center">
                    Get a free <span className='text-[red]'>{options.find(option => option.id === selectedOption)?.label}</span> quote
                </h2>
                {commonFields}
            </form>
        );
    };

    return (
        <LoadScript
            googleMapsApiKey="AIzaSyC5WzOSNJzkMATaZOnBUCV_ryvr4RsNQMY" // Replace with your actual API key
            libraries={['places']}
        >
            <div className="w-full flex flex-col items-center lg:mt-[50px] mb-[100px]">
                <div className="w-[85%] lg:w-[50%] flex flex-wrap lg:flex-row flex-row justify-center mb-6">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => handleOptionClick(option.id)}
                            className={`cursor-pointer p-4 lg:w-[113px] w-[90px] flex flex-col text-center items-center mb-2 justify-center rounded-md shadow-md me-2 border ${selectedOption === option.id ? 'bg-red-100 border-red-500' : 'bg-white border-gray-300'}`}
                        >
                            <div className="text-2xl mb-2">{option.icon}</div>
                            <div className={`text-xs lg:text-sm font-bold ${selectedOption === option.id ? 'text-red-500' : 'text-gray-700'}`}>
                                {option.label}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="w-[80%] lg:w-[80%] p-6 rounded-md shadow-md bg-white">
                    {renderForm()}
                </div>
            </div>
        </LoadScript>
    );
};

export default UserForm2;
